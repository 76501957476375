import { SIGNED_IN, SIGNED_OUT, SET_GOOGLE_PERMISSIONS} from '../constants';

const INITIAL_STATE = {
  isSignedIn: false,
  userId: null,
  permissions: []
};

export default (state = INITIAL_STATE, action: any) => {
  switch (action.type)  {
    case SIGNED_IN:
      console.error('Setting Google user id to ' + action.payload);
      return {...state, isSignedIn: true, userId: action.payload};
    case SIGNED_OUT:
      return {...state, isSignedIn: false, userId: null};
    case SET_GOOGLE_PERMISSIONS:
      return {...state, permissions: action.payload.permissions};
    default:
      return state;
  }
};