import React from 'react';
import { connect, MapDispatchToProps } from 'react-redux';
import { GoogleDriveFile } from '../types/google';
import Paper from '@material-ui/core/Paper';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import Button from '@material-ui/core/Button';
// import { startGoogleDoc } from '../actions';
import { CSSProperties } from '@material-ui/styles';

const startDoc = (documentId: string) => {
  console.warn('Clicked on file with ID of ' + documentId);

}

export default function GoogleFile(props: any) {
  const { file, startGoogleDoc } = props;
  const googleFile: GoogleDriveFile = file;
  const webViewLink: string = (googleFile.webViewLink) ? googleFile.webViewLink : 'none';
  const webContentLink: string = (googleFile.webContentLink) ? googleFile.webContentLink : 'none';
  const documentId: string = (googleFile.id) ? googleFile.id : 'none';
  const aStyle: CSSProperties = {
    'textalign': 'right',
    'color': 'white'
  }

  return (
    <Card key={googleFile.id} >
      <CardActionArea onClick={() => startGoogleDoc(documentId)}>
        {googleFile.name}: {googleFile.id}
      </CardActionArea>
      <Button style={aStyle} href={webContentLink} target='_blank'>
        DOWNLOAD
      </Button>
      <Button style={aStyle} href={webViewLink} target='_blank'>
        PREVIEW
      </Button>
    </Card>
  );
}

/*
const mapStateToProps = (state: any) => {
  return {
  };
}

export default connect(
  mapStateToProps,
  { startGoogleDoc }
)(GoogleFile);
*/
// <Button size='small' color='primary' onClick={() => startGoogleDoc(webContentLink)}>
//          Present
//        </Button>