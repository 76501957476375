import React from 'react';
import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import GoogleButtons from './google-buttons';

declare global {
  interface Window {
    gapi: any;
  }
}

interface GoogleAuthProps {
  signIn: () => void;
  signOut: () => void;
  openDrive: () => void;
  // injected props:
  isSignedIn: boolean;
  currentFolder: string;
  documentId: string;
}

interface GoogleAuthState {
}

class GoogleAuth extends React.Component <GoogleAuthProps, GoogleAuthState> {

  private onSignInClick = () => {
    console.log('Signing in to Google');
    this.props.signIn();
  }

  private onSignOutClick = () => {
    console.log('Signing out of Google');
    this.props.signOut();
  }

  private onOpenDrive = async () => {
    console.warn('Requesting list of Google Drive files');
    this.props.openDrive();
  }

  render() {
    return (
      <div>
        <Grid key={'google-auth'} item>
          <GoogleButtons isSignedIn={this.props.isSignedIn} onSignInClick={this.onSignInClick} onSignOutClick={this.onSignOutClick} onOpenDrive={this.onOpenDrive} documentId={this.props.documentId} />
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    isSignedIn: state.auth.isSignedIn,
    currentFolder: state.files.currentFolder,
    documentId: state.activity.documentId
  };
}

export default connect(mapStateToProps, {})(GoogleAuth);
