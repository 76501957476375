const GoogleApiConstants = {
  USER_AUTHENTICATED: 'GOOGLE_USER_AUTHENTICATED',
  USER_LOGGED_OUT: 'GOOGLE_USER_LOGGED_OUT',
  FILES_RECEIVED: 'GOOGLE_FILES_RECEIVED',
  DIRECTORY_CHANGED: 'GOOGLE_DIRECTORY_CHANGED',

  SCOPES: {
    DRIVE: 'https://www.googleapis.com/auth/drive',  
    DRIVE_FILE: 'https://www.googleapis.com/auth/drive.file',
    DRIVE_METADATA: 'https://www.googleapis.com/auth/drive.metadata',
    DRIVE_METADATA_READONLY: 'https://www.googleapis.com/auth/drive.metadata.readonly',
    DRIVE_READ_ONLY: 'https://www.googleapis.com/auth/drive.readonly'
  },

  // Permission strings taken from the documentation: https://developers.google.com/drive/v3/reference/permissions
  PERMISSIONS: {
    TYPES: {
      USER: 'user',
      GROUP: 'group',
      DOMAIN: 'domain',
      ANYONE: 'anyone'
    },
    ROLES: {
      ORGANIZER: 'organizer',
      OWNER: 'owner',
      WRITER: 'writer',
      COMMENTER: 'commenter',
      READER: 'reader'
    },
    IDS: {
      ANYONE_WITH_LINK: 'anyoneWithLink'
    }
  }
};

export default GoogleApiConstants;