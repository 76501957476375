// Local random username actions
export const SET_USER_ID = 'SET_USER_ID';
// Notification related constants
export const NOTIFICATION_EXPIRED = 'NOTIFICATION_EXPIRED';
// Google account related constants
export const ON_SIGN_IN = 'ON_SIGN_IN';
export const ON_SIGN_OUT = 'ON_SIGN_OUT';
export const SIGNED_IN = 'SIGNED_IN';
export const SIGNED_OUT = 'SIGNED_OUT';
// Websockets actions sent
export const TEST_WEBSOCKET = 'TEST_WEBSOCKET';
export const REGISTER = 'REGISTER';
export const CHANGE_SESSION = 'CHANGE_SESSION';
export const CHANGE_DOCUMENT = 'CHANGE_DOCUMENT';
export const CLOSE_DOCUMENT = 'CLOSE_DOCUMENT';
export const CHANGE_ACTIVITY = 'CHANGE_ACTIVITY';
// Websockets actions received
export const USER_TESTED = 'USER_TESTED';
export const REGISTERED = 'REGISTERED';
export const USER_JOINED = 'USER_JOINED';
export const USER_LEFT = 'USER_LEFT';
export const SESSION_CHANGED = 'SESSION_CHANGED';
export const DOCUMENT_CHANGED = 'DOCUMENT_CHANGED';
export const DOCUMENT_CLOSED = 'DOCUMENT_CLOSED';
export const ACTIVITY_CHANGED = 'ACTIVITY_CHANGED';
// Basic room setup actions
export const INITIALIZE_GOOGLE = 'INITIALIZE_GOOGLE';
export const SET_ROOM_ID = 'SET_ROOM_ID';
export const SET_MEETING_TYPE = 'SET_MEETING_TYPE';
export const SET_ROOM_DIMENSIONS = 'SET_ROOM_DIMENSIONS';
// Video actions
export const DEVICE_READY = 'DEVICE_READY';
export const AUDIO_MUTED = 'AUDIO_MUTED';
export const AUDIO_UNMUTED = 'AUDIO_UNMUTED';
export const VIDEO_MUTED = 'VIDEO_MUTED';
export const VIDEO_UNMUTED = 'VIDEO_UNMUTED';
export const DEVICE_CONNECTED = 'DEVICE_CONNECTED';
export const DEVICE_DISCONNECTED = 'DEVICE_DISCONNECTED';
export const ENTERED_ROOM = 'ENTERED_ROOM';
export const LEFT_ROOM = 'LEFT_ROOM';
// Local participant video actions
export const LOCAL_PARTICIPANT_JOINING_ROOM = 'LOCAL_PARTICIPANT_JOINING_ROOM';
export const LOCAL_PARTICIPANT_JOINED_ROOM = 'LOCAL_PARTICIPANT_JOINED_ROOM';
export const LOCAL_PARTICIPANT_LEAVING_ROOM = 'LOCAL_PARTICIPANT_LEAVING_ROOM';
export const LOCAL_PARTICIPANT_LEFT_ROOM = 'LOCAL_PARTICIPANT_LEFT_ROOM';
// Remote participant video actions
export const PARTICIPANTS_UPDATED = 'PARTICIPANTS_UPDATED';
export const PARTICIPANT_JOINED_VIDEO = 'PARTICIPANT_JOINED_VIDEO';
export const PARTICIPANT_LEFT_VIDEO = 'PARTICIPANT_LEFT_VIDEO';
export const PARTICIPANT_JOINING_ROOM = 'PARTICIPANT_JOINING_ROOM';
export const PARTICIPANT_JOINED_ROOM = 'PARTICIPANT_JOINED_ROOM';
export const PARTICIPANT_LEAVING_ROOM = 'PARTICIPANT_LEAVING_ROOM';
export const PARTICIPANT_LEFT_ROOM = 'PARTICIPANT_LEFT_ROOM';
// Room activity types
export const SCREENSHARE = 'SCREENSHARE';
export const GOOGLE_DOC = 'GOOGLE_DOC';
export const GOOGLE_SLIDE = 'GOOGLE_SLIDE';
export const GOOGLE_SHEET = 'GOOGLE_SHEET';
export const GOOGLE_DRIVE = 'GOOGLE_DRIVE';
export const WHITEBOARD = 'WHITEBOARD';
export const PRESENTATION = 'PRESENTATION';
// Room activity actions
export const START_SCREENSHARE = 'START_SCREENSHARE';
export const STARTED_SCREENSHARE = 'STARTED_SCREENSHARE';
export const END_SCREENSHARE = 'END_SCREENSHARE';
export const ENDED_SCREENSHARE = 'ENDED_SCREENSHARE';
export const START_WHITEBOARD = 'START_WHITEBOARD';
export const STARTED_WHITEBOARD = 'STARTED_WHITEBOARD';
export const END_WHITEBOARD = 'END_WHITEBOARD';
export const ENDED_WHITEBOARD = 'ENDED_WHITEBOARD';
export const START_PRESENTATION = 'START_PRESENTATION';
export const STARTED_PRESENTATION = 'STARTED_PRESENTATION';
export const END_PRESENTATION = 'END_PRESENTATION';
export const ENDED_PRESENTATION = 'ENDED_PRESENTATION';
export const START_GOOGLE_DOC = 'START_GOOGLE_DOC';
export const STARTED_GOOGLE_DOC = 'STARTED_GOOGLE_DOC';
export const END_GOOGLE_DOC = 'END_GOOGLE_DOC';
export const ENDED_GOOGLE_DOC = 'ENDED_GOOGLE_DOC';
export const START_GOOGLE_SLIDE = 'START_GOOGLE_SLIDE';
export const STARTED_GOOGLE_SLIDE = 'STARTED_GOOGLE_SLIDE';
export const END_GOOGLE_SLIDE = 'END_GOOGLE_SLIDE';
export const ENDED_GOOGLE_SLIDE = 'ENDED_GOOGLE_SLIDE';
export const START_GOOGLE_SHEET = 'START_GOOGLE_SHEET';
export const STARTED_GOOGLE_SHEET = 'STARTED_GOOGLE_SHEET';
export const END_GOOGLE_SHEET = 'END_GOOGLE_SHEET';
export const ENDED_GOOGLE_SHEET = 'ENDED_GOOGLE_SHEET';
export const OPEN_GOOGLE_DRIVE = 'OPEN_GOOGLE_DRIVE';
export const OPENED_GOOGLE_DRIVE = 'OPENED_GOOGLE_DRIVE';
export const CLOSE_GOOGLE_DRIVE = 'CLOSE_GOOGLE_DRIVE';
export const CLOSED_GOOGLE_DRIVE = 'CLOSED_GOOGLE_DRIVE';
export const SET_GOOGLE_PERMISSIONS = 'SET_GOOGLE_PERMISSIONS';
export const SMALL_GROUP_VIDEO = 'SMALL_GROUP_VIDEO';
export const MEDIUM_GROUP_VIDEO = 'MEDIUM_GROUP_VIDEO';
export const SMALL_GROUP_ACTIVITY = 'SMALL_GROUP_ACTIVITY';
export const MEDIUM_GROUP_ACTIVITY = 'MEDIUM_GROUP_ACTIVITY';
// Google Drive specific actions
export const ON_OPEN_GOOGLE_DRIVE = 'ON_OPEN_GOOGLE_DRIVE';
export const GOOGLE_RESPONSE = 'GOOGLE_RESPONSE';
export const LIST_GOOGLE_DRIVE_FILES = 'LIST_GOOGLE_DRIVE_FILES';
export const LIST_GOOGLE_DRIVE_FOLDERS = 'LIST_GOOGLE_DRIVE_FOLDERS';
export const SELECT_DRIVE_FOLDER = 'SELECT_DRIVE_FOLDER';
export const GET_GOOGLE_FILE_DETAILS = 'GET_GOOGLE_FILE_DETAILS';
export const SHARE_WITH_ANYONE_WITH_LINK = 'SHARE_WITH_ANYONE_WITH_LINK';
export const REVOKE_FROM_ANYONE_WITH_LINK = 'REVOKE_FROM_ANYONE_WITH_LINK';