import React from 'react';
import { connect } from 'react-redux';
import history from '../history';
import commonUtility from '../utils/common-utility';
import { Container, Grid, Fab } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { CSSProperties } from '@material-ui/styles';
// import { endGoogleDoc } from '../actions';

const getGoogleDoc = (documentId: string) => {
  const docStyle = {
    height: '100vh',
    width: 'calc(100%)',
    frameBorder: '0'
  };

  const iframeViewer = <iframe src={`https://drive.google.com/open?id=${documentId}`} style={docStyle} />;
  // const iframeViewer = <iframe src={documentUri} style={docStyle} />;
  return (
    <div>{iframeViewer}</div>
  );
}

function GoogleDoc(props: any) {
  const { documentId, endGoogleDoc } = props;
  console.log('returning googleDoc with ID ' + documentId);
  const closeButtonStyle: CSSProperties = {
    'position': 'absolute',
    'top': 'calc(5vh)',
    'right': 'calc(30%)'
  };

  const closeButton = <Fab size='medium' color='primary' onClick={() => endGoogleDoc(props.documentId)} style={closeButtonStyle}>
      <CloseIcon />
    </Fab>;

  if (!documentId) {
    return null;
  } 
  else { 
    return (
    <div>
      {documentId && endGoogleDoc ? closeButton : null}
      {documentId ? getGoogleDoc(documentId) : null }
    </div>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
  }
}

export default connect(mapStateToProps, { /* endGoogleDoc */ })(GoogleDoc);
/*
    <div >
      <Container maxWidth='xl'>
        <Grid container direction='row' alignItems='stretch'>
          <Grid item xs={12}>
            
          </Grid>
        </Grid>
      </Container>
    </div>
*/