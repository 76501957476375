import React from 'react';
import { connect } from 'react-redux';
import Snackbar, { SnackbarOrigin } from '@material-ui/core/Snackbar';
import { SnackbarContentProps } from '@material-ui/core/SnackbarContent';
import { notificationExpired } from '../actions';

interface NotificationProps {
  type: string; // TODO: make this an enum with standard, warning, error, success, etc
  // injected props:
  notificationMessage: string;
  notificationExpired: () => void;
}

interface NotificationState {
  isOpen: boolean;
}

class Notification extends React.Component <NotificationProps, NotificationState> {
  constructor(props: NotificationProps) {
    super(props);
    this.state = {
      isOpen: false
    }
  }
  
  handleNotificationClose = () => {
    this.props.notificationExpired();
    this.setState({
      isOpen: false
    });
  }

  render() {
    if (this.props.notificationMessage) {
      console.warn('notificationMessage has a value: ' + this.props.notificationMessage);
      return (
        <Snackbar
          anchorOrigin={{vertical: 'top', horizontal: 'right'} as any}
          message={this.props.notificationMessage}
          autoHideDuration={5000}
          onClose={this.handleNotificationClose}
          open={true}
          ContentProps={{'aria-describedby': 'snackbar-message-id'} as SnackbarContentProps}
        />
      );
    }
    else {
      console.warn('notificationMessage does not have a value: ' + this.props.notificationMessage);
      return null;
    }
    
  }
}

const mapStateToProps = (state: any) => {
  return {
    notificationMessage: state.room.notificationMessage
  };
}

export default connect(mapStateToProps, { notificationExpired })(Notification);