import {
  OPEN_GOOGLE_DRIVE,
  OPENED_GOOGLE_DRIVE,
  CLOSE_GOOGLE_DRIVE,
  SELECT_DRIVE_FOLDER,
  GOOGLE_RESPONSE,
  LIST_GOOGLE_DRIVE_FILES,
  LIST_GOOGLE_DRIVE_FOLDERS,
  GET_GOOGLE_FILE_DETAILS,
  SHARE_WITH_ANYONE_WITH_LINK,
  REVOKE_FROM_ANYONE_WITH_LINK,
  START_GOOGLE_DOC,
  STARTED_GOOGLE_DOC,
  END_GOOGLE_DOC,
  ENDED_GOOGLE_DOC,
  START_GOOGLE_SLIDE,
  STARTED_GOOGLE_SLIDE,
  END_GOOGLE_SLIDE,
  ENDED_GOOGLE_SLIDE,
  START_GOOGLE_SHEET,
  STARTED_GOOGLE_SHEET,
  END_GOOGLE_SHEET,
  ENDED_GOOGLE_SHEET,
} from '../constants';

const INITIAL_STATE = {
  userId: '',
  fileId: '',
  activeFileDetails: null,
  hasPermissions: '',
  files: [],
  folders: [],
  currentFolder: 'root',
  isDriveOpening: false,
  isDriveOpen: false,
  isDriveClosed: true
};

export default (state = INITIAL_STATE, action: any) => {
  switch (action.type)  {
    case GOOGLE_RESPONSE:
      {
        console.error('Received Google Response! ');
        switch (action.payload.requestType) {
          case LIST_GOOGLE_DRIVE_FILES:
            console.log('Files are: ' + action.payload.response.files);
            return {
              ...state,
              isDriveOpening: false,
              isDriveOpen: true,
              isDriveClosed: false,
              files: (action.payload.response && action.payload.response.files) ? action.payload.response.files : []
            };
          case LIST_GOOGLE_DRIVE_FOLDERS:
            console.log('Folders are: ' + action.payload.response.files);
            return {
              ...state,
              isDriveOpening: false,
              isDriveOpen: true,
              isDriveClosed: false,
              folders: (action.payload.response && action.payload.response.files) ? action.payload.response.files : []
            };
          case GET_GOOGLE_FILE_DETAILS:
            return {
              ...state,
              fileId: action.payload.documentId,
              activeFileDetails: action.payload.response //TODO: See what is returned here
            };
          case SHARE_WITH_ANYONE_WITH_LINK:
            return {
              ...state,
              isShared: true // TODO: Get response type from Google to set this appropriately
            };
          case REVOKE_FROM_ANYONE_WITH_LINK:
            return {
              ...state,
              isShared: false // TODO: Get response type from Google
            };
          default:
            return state;
        }
      }
    case SELECT_DRIVE_FOLDER:
      console.log('Selecting folder: ' + action.payload.folderId);
      return {
        ...state,
        currentFolder: action.payload.folderId
      };
    case CLOSE_GOOGLE_DRIVE:
      {
        return {
          ...state,
          isDriveOpening: false,
          isDriveOpen: false,
          isDriveClosed: true
        };
      }
    case START_GOOGLE_DOC:
    case STARTED_GOOGLE_DOC:
    case START_GOOGLE_SHEET:
    case START_GOOGLE_SLIDE:
      {
        return {
          ...state,
          isDriveOpening: false,
          isDriveOpen: false,
          isDriveClosed: true
        };
      }
    default:
      return state;
  }
}