import React from 'react';
import { connect } from 'react-redux';
// import { setGooglePermissions } from '../actions';
import { GoogleDriveFile } from '../types/google';
import GoogleFile from './google-file';
import GoogleFolder from './google-folder';

// TODO: Refactor this into a functional component

interface GoogleDriveProps {
  onFolderSelected: (folderId: string) => void;
  startGoogleDoc: (documentId: string) => void;
  // injected props:
  // TODO: Refactor to take into account the proper types for Google files and folders
  files: any[];
  folders: any[];
  currentFolder: string;
  isSignedIn: boolean;
  permissions: string[];
  isDriveOpening: boolean;
  isDriveOpen: boolean;
  isDriveClosed: boolean;
  // setGooglePermissions: (permissions: string[]) => void;
}

interface GoogleDriveState {
}

class GoogleDrive extends React.Component <GoogleDriveProps, GoogleDriveState> {

  constructor(props: GoogleDriveProps) {
    super(props);
  }

  componentDidMount() {
    console.error('google-drive component mounted');
  }

  getNavigationBar = () => {
    //TODO: Add a navigation bar, so user can navigate back to parent directory
  }

  // TODO: Keep track of current folder, so we can list only files and folders in current folder
  getFolders = () => {
    let folderDivs: JSX.Element[] = [];
    this.props.folders.forEach((file: GoogleDriveFile) => {
      folderDivs.push(<div key={'folder-div-' + file.id}><GoogleFolder file={file} onFolderSelected={this.props.onFolderSelected} /></div>)
    });
    return folderDivs;
  }

  getFiles = () => {
    let fileDivs: JSX.Element[] = [];
    this.props.files.forEach((file: GoogleDriveFile) => {
      fileDivs.push(<div key={'file-div-' + file.id}><GoogleFile file={file} startGoogleDoc={this.props.startGoogleDoc} /></div>)
    });
    return fileDivs;
  }

  render() {
    if (this.props.isDriveClosed && !this.props.isDriveOpening) {
      return null;
    }
    else if (this.props.isDriveOpening) {
      return (
        <div>Opening Drive...</div>
      );
    }
    else {
      return(
        <div>
          {this.getFolders()}
          {this.getFiles()}
        </div>
      );
    }
  }
}

const mapStateToProps = (state: any) => {
  return {
    files: state.files.files,
    folders: state.files.folders,
    currentFolder: state.files.currentFolder,
    isSignedIn: state.auth.isSignedIn,
    permissions: state.auth.permissions,
    isDriveOpening: state.files.isDriveOpening,
    isDriveOpen: state.files.isDriveOpen,
    isDriveClosed: state.files.isDriveClosed
  }
}

export default connect(mapStateToProps, {})(GoogleDrive);