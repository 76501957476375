import React from 'react';
import Fab from '@material-ui/core/Fab';
import Lock from '@material-ui/icons/Lock';
import InsertDriveFile from '@material-ui/icons/InsertDriveFile'
import { createStyles, Theme, makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => 
  createStyles({
    root: {
      display: 'flex',
      justifyContent: 'space-around',
      overflow: 'hidden',
      backgroundColor: '#222',
    },
    gridList: {
      backgroundColor: '#222',
      height: 'calc(100vh)',
      width: 'calc(100%)',
      // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
      transform: 'translateZ(0)',
    },
    gridListTile: {
      height: 'calc(100vh)',
      width: 'calc(100%)'
    },
    titleBar: {
      background:
        'linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, ' +
        'rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
    },
    icon: {
      color: 'white',
      height: '100%',
      width: '100%'
    },
    video: {
      height: 'calc(100%)',
      width: 'calc(100%)',
      'object-fit': 'contain'
    },
    buttonGrid: {
      position: 'absolute',
      top: 'calc(70vh)',
      margin: theme.spacing(1),
    },
    extendedButton: {
      margin: theme.spacing(1),
    },
    extendedIcon: {
      marginRight: theme.spacing(1),
    }
  })
);


// TODO: Move the functions to actions, and connect this component using redux
export default function GoogleButtons(props: any) {
  
  console.warn('this.props.isSigned in is ' + JSON.stringify(props.isSignedIn));
  const { isSignedIn, onSignInClick, onSignOutClick, onOpenDrive, documentId} = props;
  const classes = useStyles();

  /*
    Implement this in user profile, etc
          <Fab variant={'extended'} size={'medium'} color={'secondary'} arial-label={'Sign out of Google'} className={classes.extendedButton} onClick={onSignOutClick}>
          <Lock className={classes.extendedIcon} />
          DISCONNECT
        </Fab>
  */
 if (!isSignedIn) {
  return (
    <Fab variant={'extended'} size={'medium'} color={'primary'} arial-label={'Sign into Google'} className={classes.extendedButton} onClick={onSignInClick}>
      <Lock className={classes.extendedIcon} />
      CONNECT WITH GOOGLE DRIVE
    </Fab>
    );
  }
  else if (!documentId) {
    return (
      <div>
        <Fab variant={'extended'} size={'medium'} color={'secondary'} arial-label={'Connect Google Drive'} className={classes.extendedButton} onClick={onOpenDrive}>
          <InsertDriveFile className={classes.extendedIcon} />
            SHARE A DOCUMENT
        </Fab>
      </div>
    );
  }
  else {
    return null;
  }
}
