import React from 'react';
import { connect, MapDispatchToProps } from 'react-redux';
import { GoogleDriveFile } from '../types/google';
import Paper from '@material-ui/core/Paper';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import Button from '@material-ui/core/Button';
import { selectFolder } from '../actions';
import { CSSProperties } from '@material-ui/styles';

function GoogleFolder(props: any) {
  const { file, onFolderSelected, selectDriveFolder } = props;
  const googleFolder: GoogleDriveFile = file;
  const folderId: string = (googleFolder.id) ? googleFolder.id : 'root';
  const aStyle: CSSProperties = {
    'textalign': 'right',
    'color': 'white'
  }

  return (
    <Card key={googleFolder.id} >
      <CardActionArea onClick={() => onFolderSelected(folderId)}>
        {JSON.stringify(googleFolder.name)}
      </CardActionArea>
    </Card>
  );
}
const mapStateToProps = (state: any) => {
  return {
  };
}

export default connect(
  mapStateToProps,
  { selectFolder }
)(GoogleFolder);

// <Button size='small' color='primary' onClick={() => startGoogleDoc(webContentLink)}>
//          Present
//        </Button>