import {
  SET_USER_ID,
  ON_SIGN_IN,
  SIGNED_IN,
  ON_SIGN_OUT,
  SIGNED_OUT,
  USER_TESTED,
  REGISTERED,
  SESSION_CHANGED,
  CHANGE_ACTIVITY,
  ACTIVITY_CHANGED,
  DOCUMENT_CHANGED,
  SET_ROOM_ID,
  SET_MEETING_TYPE,
  LOCAL_PARTICIPANT_JOINING_ROOM,
  LOCAL_PARTICIPANT_JOINED_ROOM,
  LOCAL_PARTICIPANT_LEAVING_ROOM,
  LOCAL_PARTICIPANT_LEFT_ROOM,
  PARTICIPANTS_UPDATED,
  PARTICIPANT_JOINED_VIDEO,
  PARTICIPANT_LEFT_VIDEO,
  PARTICIPANT_JOINING_ROOM,
  PARTICIPANT_JOINED_ROOM,
  PARTICIPANT_LEAVING_ROOM,
  PARTICIPANT_LEFT_ROOM,
  SET_ROOM_DIMENSIONS,
  USER_JOINED,
  USER_LEFT,
  DOCUMENT_CLOSED,
  REGISTER,
  NOTIFICATION_EXPIRED
} from '../constants';

import {
  GOOGLE_DOC,
  GOOGLE_SLIDE,
  GOOGLE_SHEET,
  GOOGLE_DRIVE,
  SCREENSHARE,
  WHITEBOARD,
  PRESENTATION,
  START_SCREENSHARE,
  STARTED_SCREENSHARE,
  END_SCREENSHARE,
  ENDED_SCREENSHARE,
  INITIALIZE_GOOGLE,
  START_GOOGLE_DOC,
  STARTED_GOOGLE_DOC,
  END_GOOGLE_DOC,
  ENDED_GOOGLE_DOC,
  START_GOOGLE_SLIDE,
  STARTED_GOOGLE_SLIDE,
  END_GOOGLE_SLIDE,
  ENDED_GOOGLE_SLIDE,
  START_GOOGLE_SHEET,
  STARTED_GOOGLE_SHEET,
  END_GOOGLE_SHEET,
  ENDED_GOOGLE_SHEET,
  OPEN_GOOGLE_DRIVE,
  OPENED_GOOGLE_DRIVE,
  CLOSE_GOOGLE_DRIVE,
  SELECT_DRIVE_FOLDER,
  SET_GOOGLE_PERMISSIONS,
  START_WHITEBOARD,
  STARTED_WHITEBOARD,
  END_WHITEBOARD,
  ENDED_WHITEBOARD,
  START_PRESENTATION,
  STARTED_PRESENTATION,
  END_PRESENTATION,
  ENDED_PRESENTATION,
  ON_OPEN_GOOGLE_DRIVE,
  GOOGLE_RESPONSE,
  LIST_GOOGLE_DRIVE_FILES,
  LIST_GOOGLE_DRIVE_FOLDERS,
  GET_GOOGLE_FILE_DETAILS,
  SHARE_WITH_ANYONE_WITH_LINK,
  REVOKE_FROM_ANYONE_WITH_LINK
} from '../constants';

import CommonUtility from '../utils/common-utility';
import { RemoteParticipant } from 'twilio-video';
import WebSocketService from '../api/websocket-service';

// TODO: Move websocket service to middleware, as per this article: https://medium.com/@ianovenden/redux-websocket-integration-c1a0d22d3189
let webSocketService: WebSocketService;


export const setUserId = () => {
  // creates a random username for the websocket service and Twilio video
  const userId = CommonUtility.getUuid();
  return {
    type: SET_USER_ID,
    payload: {
      userId: userId
    }
  };
}

export const notificationExpired = () => {
  return {
    type: NOTIFICATION_EXPIRED,
  };
}

export const initializeGoogle = () => {
  return {
    type: INITIALIZE_GOOGLE
  };
};

export const onSignIn = () => {
  return {
    type: ON_SIGN_IN
  };
};

export const signedIn = (userId: string) => {
  return {
    type: SIGNED_IN,
    payload: userId
  };
};

export const onSignOut = () => {
  return {
    type: ON_SIGN_OUT
  };
};

export const signedOut = () => {
  return {
    type: SIGNED_OUT
  };
};

export const registerWebsocketService = async (userId: string, sessionId: string) => {
  webSocketService = new WebSocketService({userId: userId, sessionId: sessionId});
  return {
    type: REGISTER
  }
}

export const registered = (userId: string, sessionId: string) => {
  return {
    type: REGISTERED,
    payload: {
      userId: userId,
      sessionId: sessionId
    }
  };
};

export const userJoinedWebsockets = (userId: string, sessionId: string) => {
  return {
    type: USER_JOINED,
    payload: {
      userId: userId,
      sessionId: sessionId
    }
  };
};

export const userLeftWebsockets = (userId: string, sessionId: string) => {
  return {
    type: USER_LEFT,
    payload: {
      userId: userId,
      sessionId: sessionId
    }
  };
};

export const documentChanged = (userId: string, documentId: string) => {
  return {
    type: DOCUMENT_CHANGED,
    payload: {
      userId: userId,
      documentId: documentId
    }
  };
};

export const documentClosed = (userId: string) => {
  return {
    type: DOCUMENT_CLOSED,
    payload: {
      userId: userId
    }
  };
};

export const sessionChanged = (userId: string, oldSessionId: string, newSessionId: string) => {
  return {
    type: SESSION_CHANGED,
    payload: {
      userId: userId,
      oldSessionId: oldSessionId,
      newSessionId: newSessionId
    }
  };
};

export const selectFolder = (folderId: string) => {
  return {
    type: SELECT_DRIVE_FOLDER,
    payload: {
      folderId: folderId
    }
  };
};

export const onGoogleResponse = (requestType: string, response: any, documentId: string) => {
  const parsedResponse = (response && response.body) ? JSON.parse(response.body) : response;
  // TODO: put switch statement here instead of the reducer
  if (requestType === SHARE_WITH_ANYONE_WITH_LINK) {
    webSocketService.changeDocument(documentId);
  }
  return {
    type: GOOGLE_RESPONSE,
    payload: {
      requestType: requestType,
      response: parsedResponse
    }
  };
};

export const changeActivity = (activityType: string) => {
  webSocketService.changeActivity(activityType);
  return {
    type: CHANGE_ACTIVITY,
    payload: {
      activityType: activityType
    }
  };
};

export const activityChanged = (userId: string, sessionId: string, activityType: string) => {
  return {
    type: ACTIVITY_CHANGED,
    payload: {
      userId: userId,
      sessionId: sessionId,
      activityType: activityType
    }
  };
};

export const setRoomId = (roomId: string) => {
  return {
    type: SET_ROOM_ID,
    payload: {
      roomId: roomId
    }
  };
};

export const setMeetingType = (meetingType: string) => {
  return {
    type: SET_MEETING_TYPE,
    payload: {
      meetingType: meetingType
    }
  };
};

export const setRoomDimensions = (width: number, height: number) => {
  return {
    type: SET_ROOM_DIMENSIONS,
    payload: {
      width: width,
      height: height
    }
  };
};

export const joinRoom = () => {
  // TODO: generalize this for all participants
  return {
    type: LOCAL_PARTICIPANT_JOINING_ROOM
  };
};

export const joinedRoom = () => {
  return {
    type: LOCAL_PARTICIPANT_JOINED_ROOM
  };
};

export const leaveRoom = () => {
  if (webSocketService) {
    webSocketService.destroy();
  }
  return {
    type: LOCAL_PARTICIPANT_LEAVING_ROOM
  };
};

export const leftRoom = () => {

  return {
    type: LOCAL_PARTICIPANT_LEFT_ROOM
  };
};

export const participantsUpdated = (participants: Map<string, RemoteParticipant>) => {
  return {
    type: PARTICIPANTS_UPDATED,
    payload: {
      participants: participants
    }
  };
};

export const participantJoinedVideo = (participant: RemoteParticipant) => {
  return{ 
    type: PARTICIPANT_JOINED_VIDEO,
    payload: {
      participant: participant
    }
  };
};

export const participantLeftVideo = (participant: RemoteParticipant) => {
  return {
    type: PARTICIPANT_LEFT_VIDEO,
    payload: {
      participant: participant
    }
  }
}
export const startScreenshare = (userId: string) => {
  return {
    type: START_SCREENSHARE,
    payload: userId
  };
};

export const startedScreenshare = (userId: string) => {
  return {
    type: STARTED_SCREENSHARE,
    payload: userId
  };
};

export const endScreenshare = (userId: string) => {
  return {
    type: END_SCREENSHARE,
    payload: userId
  };
};

export const endedScreenshare = (userId: string) => {
  return {
    type: ENDED_SCREENSHARE,
    payload: userId
  };
};

export const startGoogleDoc = (documentId: string, ownerId: string) => {
  //Note: important to keep ownerId so we know who has the ability to close the document
  return {
    type: START_GOOGLE_DOC,
    payload: {
      documentId: documentId,
      ownerId: ownerId
    }
  };
};

// This is incoming from remote user
export const startedGoogleDoc = (userId: string, documentId: string) => {
  // Note that this is not the Google id; this is the websockets unique userId
  return {
    type: STARTED_GOOGLE_DOC,
    payload: {
      userId: userId,
      documentId: documentId
    }
  };
};

export const endGoogleDoc = (documentId: string) => {
  webSocketService.closeDocument();
  return {
    type: END_GOOGLE_DOC,
    payload: {
      documentId: documentId
    }
  };
};

export const endedGoogleDoc = (userId: string, documentId: string) => {
  return {
    type: ENDED_GOOGLE_DOC,
    payload: {
      userId: userId,
      documentId: documentId
    }
  };
};

export const startGoogleSlide = (userId: string, documentId: string) => {
  return {
    type: START_GOOGLE_SLIDE,
    payload: {
      userId: userId,
      documentId: documentId
    }
  };
};

export const startedGoogleSlide = (userId: string, documentId: string) => {
  return {
    type: STARTED_GOOGLE_SLIDE,
    payload: {
      userId: userId,
      documentId: documentId
    }
  };
};

export const endGoogleSlide = (userId: string, documentId: string) => {
  return {
    type: END_GOOGLE_SLIDE,
    payload: {
      userId: userId,
      documentId: documentId
    }
  };
};

export const endedGoogleSlide = (userId: string, documentId: string) => {
  return {
    type: ENDED_GOOGLE_SLIDE,
    payload: {
      userId: userId,
      documentId: documentId
    }
  };
};

export const startGoogleSheet = (userId: string, documentId: string) => {
  return {
    type: START_GOOGLE_SHEET,
    payload: {
      userId: userId,
      documentId: documentId
    }
  };
};

export const startedGoogleSheet = (userId: string, documentId: string) => {
  return {
    type: STARTED_GOOGLE_SHEET,
    payload: {
      userId: userId,
      documentId: documentId
    }
  };
};

export const endGoogleSheet = (userId: string, documentId: string) => {
  return {
    type: END_GOOGLE_SHEET,
    payload: {
      userId: userId,
      documentId: documentId
    }
  };
};

export const endedGoogleSheet = (userId: string, documentId: string) => {
  return {
    type: ENDED_GOOGLE_SHEET,
    payload: {
      userId: userId,
      documentId: documentId
    }
  };
};

export const openGoogleDrive = (userId: string) => {
  return {
    type: OPEN_GOOGLE_DRIVE,
    payload: {
      userId: userId
    }
  };
};

export const openedGoogleDrive = (userId: string) => {
  return {
    type: OPENED_GOOGLE_DRIVE,
    payload: {
      userId: userId
    }
  };
};

export const closeGoogleDrive = () => {
  return {
    type: CLOSE_GOOGLE_DRIVE
  };
};

export const setGooglePermissions = (permissions: string[]) => {
  return {
    type: SET_GOOGLE_PERMISSIONS,
    payload: {
      permissions: permissions
    }
  };
};

export const startWhiteboard = (userId: string, documentId: string, collectionId: string) => {
  return {
    type: START_WHITEBOARD,
    payload: {
      userId: userId,
      documentId: documentId,
      collectionId: collectionId
    }
  };
};

export const startedWhiteboard = (userId: string, documentId: string, collectionId: string) => {
  return {
    type: STARTED_WHITEBOARD,
    payload: {
      userId: userId,
      documentId: documentId,
      collectionId: collectionId
    }
  };
};

export const endWhiteboard = (userId: string, documentId: string, collectionId: string) => {
  return {
    type: END_WHITEBOARD,
    payload: {
      userId: userId,
      documentId: documentId,
      collectionId: collectionId
    }
  };
};

export const endedWhiteboard = (userId: string, documentId: string, collectionId: string) => {
  return {
    type: ENDED_WHITEBOARD,
    payload: {
      userId: userId,
      documentId: documentId,
      collectionId: collectionId
    }
  };
};

export const startPresentation = (userId: string, documentId: string, collectionId: string) => {
  return {
    type: START_PRESENTATION,
    payload: {
      userId: userId,
      documentId: documentId,
      collectionId: collectionId
    }
  };
};

export const startedPresentation = (userId: string, documentId: string, collectionId: string) => {
  return {
    type: STARTED_PRESENTATION,
    payload: {
      userId: userId,
      documentId: documentId,
      collectionId: collectionId
    }
  };
};

export const endPresentation = (userId: string, documentId: string, collectionId: string) => {
  return {
    type: END_PRESENTATION,
    payload: {
      userId: userId,
      documentId: documentId,
      collectionId: collectionId
    }
  };
};

export const endedPresentation = (userId: string, documentId: string, collectionId: string) => {
  return {
    type: ENDED_PRESENTATION,
    payload: {
      userId: userId,
      documentId: documentId,
      collectionId: collectionId
    }
  };
};