import { combineReducers } from 'redux';
import authReducer from './auth-reducer';
import roomReducer from './room-reducer';
import activityReducer from './activity-reducer';
import fileReducer from './file-reducer';

export default combineReducers({
  auth: authReducer,
  room: roomReducer,
  activity: activityReducer,
  files: fileReducer
});