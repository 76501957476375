import {
  CHANGE_ACTIVITY,
  ACTIVITY_CHANGED,
  DOCUMENT_CHANGED,
  DOCUMENT_CLOSED,
  SMALL_GROUP_VIDEO,
  GOOGLE_DOC,
  GOOGLE_SLIDE,
  GOOGLE_SHEET,
  GOOGLE_DRIVE,
  SCREENSHARE,
  WHITEBOARD,
  PRESENTATION,
  START_SCREENSHARE,
  STARTED_SCREENSHARE,
  END_SCREENSHARE,
  ENDED_SCREENSHARE,
  START_GOOGLE_DOC,
  STARTED_GOOGLE_DOC,
  END_GOOGLE_DOC,
  ENDED_GOOGLE_DOC,
  START_GOOGLE_SLIDE,
  STARTED_GOOGLE_SLIDE,
  END_GOOGLE_SLIDE,
  ENDED_GOOGLE_SLIDE,
  START_GOOGLE_SHEET,
  STARTED_GOOGLE_SHEET,
  END_GOOGLE_SHEET,
  ENDED_GOOGLE_SHEET,
  START_WHITEBOARD,
  STARTED_WHITEBOARD,
  END_WHITEBOARD,
  ENDED_WHITEBOARD,
  START_PRESENTATION,
  STARTED_PRESENTATION,
  END_PRESENTATION,
  ENDED_PRESENTATION
} from '../constants';

const INITIAL_STATE = {
  ownerId: '',
  activityType: SMALL_GROUP_VIDEO,
  documentId: '',
  collectionId: '',
  startingActivity: false,
  endingActivity: false
};

export default (state = INITIAL_STATE, action: any) => {
  console.warn('Activity Reducer got an action' );
  console.warn(action.type);

  switch (action.type)  {
    case CHANGE_ACTIVITY:
      return {
        ...state,
        activityType: action.payload.activityType,
        startingActivity: true,
        endingActivity: false
      };
    case ACTIVITY_CHANGED:
      return {
        ...state,
        activityType: action.payload.activityType,
        ownerId: action.payload.userId,
        sessionId: action.payload.sessionId
      };
    case DOCUMENT_CHANGED:
      return {
        ...state,
        activityType: GOOGLE_DOC,
        documentId: action.payload.documentId,
        startingActivity: true,
        endingActivity: false
      };
    case DOCUMENT_CLOSED:
      return {
        ...state,
        activityType: GOOGLE_DOC,
        ownerId: '',
        documentId: null,
        startingActivity: false,
        endingActivity: false
      };
    case START_SCREENSHARE: 
      return {
        ownerId: action.payload.userId,
        activityType: SCREENSHARE,
        documentId: '',
        collectionId: '',
        startingActivity: true,
        endingActivity: false
      };
    case STARTED_SCREENSHARE:
      return {...state, ownerId: action.payload.userId, startingActivity: false, endingActivity: false};
    case START_GOOGLE_DOC:
      return {
        ...state,
        activityType: GOOGLE_DOC,
        ownerId: action.payload.ownerId,
        documentId: action.payload.documentId,
        collectionId: '',
        startingActivity: true,
        endingActivity: false
      };
    case STARTED_GOOGLE_DOC:
      return {...state, activityType: GOOGLE_DOC, documentId: action.payload.documentId, startingActivity: false, endingActivity: false};
    case END_GOOGLE_DOC:
      return {
        ...state,
        activityType: GOOGLE_DOC,
        ownerId: '',
        documentId: '',
        collectionId: '',
        startingActivity: false,
        endingActivity: true
      };
    case START_GOOGLE_SLIDE:
      return {...state,
        activityType: GOOGLE_SLIDE,
        ownerId: action.payload.userId,
        documentId: action.payload.documentId,
        collectionId: '',
        startingActivity: true,
        endingActivity: false
      };
    case STARTED_GOOGLE_SLIDE:
      return {...state, documentId: action.payload.documentId, startingActivity: false, endingActivity: false};
    case START_GOOGLE_SHEET:
      return {...state,
        ownerId: action.payload.userId,
        activityType: GOOGLE_SHEET,
        documentId: action.payload.documentId,
        collectionId: '',
        startingActivity: true,
        endingActivity: false
      };
    case STARTED_GOOGLE_SHEET:
      return {...state, documentId: action.payload.documentId, startingActivity: false, endingActivity: false};
    case START_WHITEBOARD:
      // NOTE: Make sure payload has { userId: string, documentId: string, collectionId: string}
      return {...state,
        ownerId: action.payload.userId,
        activityType: WHITEBOARD,
        documentId: action.payload.documentId,
        collectionId: action.payload.collectionId,
        startingActivity: true,
        endingActivity: false
      };
    case STARTED_WHITEBOARD:
      return {...state, documentId: action.payload.documentId, startingActivity: false, endingActivity: false};
    case START_PRESENTATION:
      // NOTE: Make sure payload has { userId: string, documentId: string, collectionId: string}
      return {...state,
        ownerId: action.payload.userId,
        activityType: PRESENTATION,
        documentId: action.payload.documentId,
        collectionId: action.payload.collectionId,
        startingActivity: true,
        endingActivity: false
      };
    case STARTED_PRESENTATION:
      return {...state, documentId: action.payload.documentId, startingActivity: false, endingActivity: false};
    default:
      return state;
  }
};