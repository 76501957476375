import React from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Notification from './notification';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    chatContainer: {
      backgroundColor: '#222',
      padding: theme.spacing(1),
      height: 'calc(36vh)',
      width: 'calc(100%)',
      color: '#fff'
    },
  }),
);

export default function Chat(props: any) {
  const classes: any = useStyles();
  // <strong>Chat (Placeholder)</strong>
  return (
    <Grid item xs={12} className={classes.chatContainer}>
      <Notification type={'normal'} />
    </Grid>
  );
}