import React from 'react';
import { connect } from 'react-redux';
import Modal from 'react-modal';
import GoogleDrive from './google-drive';
import { closeGoogleDrive } from '../actions';
import Fab from '@material-ui/core/Fab';
import CloseIcon from '@material-ui/icons/Close';
import { CSSProperties } from '@material-ui/styles';

interface FileModalProps {
  onFolderSelected: (folderId: string) => void;
  startGoogleDoc: (documentId: string) => void;
  // injected props:
  closeGoogleDrive: () => void;
  isDriveOpen: boolean;
}

interface FileModalState {

}

const customStyles = {
  overlay : {
    backgroundColor: 'rgba(0, 0, 40, 0.8)'
  },
  content : {
    color: 'white',
    position: 'absolute',
    top: '10px',
    right: '25%',
    left: '25%',
    overflowY: 'scroll',
    bottom: '50px',
    backgroundColor: '#282828',
    border: '1px solid #282828',
    paddingTop: '75px'
  }
};
  
class FileModal extends React.Component <FileModalProps, FileModalState> {

  render() {
    console.warn('Rendering file modal');
    const closeButtonStyle: CSSProperties = {
      'position': 'absolute',
      'top': '20px',
      'right': '20px'
    };

    const closeButton = <Fab size='medium' color='primary' onClick={this.props.closeGoogleDrive} style={closeButtonStyle}>
        <CloseIcon />
      </Fab>;

    return (
        <Modal isOpen={this.props.isDriveOpen} style={customStyles} ariaHideApp={false}>
          {closeButton}
          <div>
            <GoogleDrive onFolderSelected={this.props.onFolderSelected} startGoogleDoc={this.props.startGoogleDoc} />
          </div>
        </Modal>
      );
  }
}

const mapStateToProps = (state: any) => {
  return {
    isDriveOpen: state.files.isDriveOpen
  }
}

export default connect(mapStateToProps, { closeGoogleDrive })(FileModal);