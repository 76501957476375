import React from 'react';
import { connect } from 'react-redux';
import { startGoogleDoc, endGoogleDoc, selectFolder, onGoogleResponse, signedIn, signedOut, documentChanged } from '../actions';
import GoogleDoc from './google-doc';
import GoogleAuth from './google-auth';
import FileModal from './file-modal';
import GoogleApiConstants from '../constants/google-api-constants';
import {
  LIST_GOOGLE_DRIVE_FILES,
  LIST_GOOGLE_DRIVE_FOLDERS,
  GET_GOOGLE_FILE_DETAILS,
  SHARE_WITH_ANYONE_WITH_LINK,
  REVOKE_FROM_ANYONE_WITH_LINK
} from '../constants';

const EMAIL_SCOPE: string = 'email';
// specifies the fields returned by requests to the Drive API.
const FILE_FIELDS: string = 'id, name, mimeType, modifiedTime, createdTime, owners(me, displayName), size, parents, webContentLink, webViewLink';
const CLIENT_ID: string = process.env.REACT_APP_GOOGLE_CLIENT_ID || '';
const API_KEY: string = process.env.REACT_APP_GOOGLE_API_KEY || '';
const DISCOVERY_DOCS: string = 'https://www.googleapis.com/discovery/v1/apis/drive/v3/rest';

declare global {
  interface Window {
    gapi: any;
  }
}

interface GoogleContainerProps {
  // injected props
  roomId: string;
  meetingType: string;
  isDriveOpen: boolean;
  userId: string;
  isSignedIn: boolean;
  ownerId: string;
  documentId: string;
  activityType: string;
  signedIn: (userId: string, sessionId: string) => void;
  signedOut: () => void;
  onGoogleResponse: (requestType: string, response: any, documentId: string) => void;
  startGoogleDoc: (documentId: string, ownerId: string) => void;
  // startedGoogleDoc: (userId: string, documentId: string) => void;
  endGoogleDoc: (documentId: string) => void;
  // endedGoogleDoc: (userId: string, documentId: string) => void;
  selectFolder: (folderId: string) => void;
  documentChanged: (userId: string, documentId: string) => void;
}

interface GoogleContainerState {
}

// TODO: Move all Google functions into an api service, similar to websocket-service

class GoogleContainer extends React.Component <GoogleContainerProps, GoogleContainerState> {
  // private Auth: any;
  private gapi: any;
  // private GoogleUser: any;
  private window: Window;

  private get Auth() {
    return this.gapi.auth2.getAuthInstance();
  }

  private get GoogleUser() {
    return this.Auth.currentUser.get();
  }

  private get GoogleUserProfile() {
    return this.GoogleUser.getBasicProfile();
  }

  private get GoogleUserAvatar() {
    // const currentUser: any = this.GoogleUser;
    // console.log('current user is ' + JSON.stringify(currentUser));
    return this.GoogleUserProfile.getImageUrl();
  }

  // TODO: Use this info in the UI
  private get GoogleUserEmail() {
    return this.GoogleUserProfile.getEmail();
  }

  // TODO: Use this info in the UI
  private get GoogleUserDisplayName() {
    return this.GoogleUserProfile.getName();
  }

  constructor(props: GoogleContainerProps) {  
    super(props);
    this.window = window;
  }

  componentDidMount() {
    this.initializeGoogle();
  }

  startGoogleDoc = (documentId: string) => {
    this.shareFileWithAnyoneWithLink(documentId)
    .then(() => {
      // Note: the second argument here is the ownerId (only an owner can present their own Google Drive document)
      this.props.startGoogleDoc(documentId, this.props.userId);
    });
  }

  endGoogleDoc = (documentId: string) => {
    this.revokeFileFromAnyoneWithLink(documentId)
    .then(() => {
      this.props.endGoogleDoc(documentId);
    });
  }

  initializeGoogle = () => {
    this.window.gapi.load('client:auth2', () => {
      this.window.gapi.client.init({
        apiKey: API_KEY,
        clientId: CLIENT_ID,
        scope: EMAIL_SCOPE
      })
      .then(async () => {
        const gapi = await this.window.gapi;
        this.gapi = gapi;
        this.onAuthChange(this.Auth.isSignedIn.get());
        this.Auth.isSignedIn.listen(this.onAuthChange);
      });
    });
  }

  onAuthChange = (isSignedIn: boolean) => {
    if (isSignedIn) {
      this.props.signedIn(this.GoogleUser.getId(), this.props.roomId);
    }
    else {
      this.props.signedOut();
    }
  }

  verify = (scope: string) => {
    if (!this.Auth) {
      return Promise.reject('Could not initialize Google Client');
    }
    return this.verifyPermission(scope);
  }

  verifyPermission = (scope: string) => {
      return !this.GoogleUser.hasGrantedScopes(scope) ? this.GoogleUser.grant({scope}) : Promise.resolve();
  }

  execute = async (requestType: string, request: any, documentId?: string) => {
    // response probably shouldn't be 'any'
    
      await this.gapi.client.load('drive', 'v3', async () => {
        return request()
        .then((response: any) => {
          const parsedResponse = (response && response.body) ? JSON.parse(response.body) : response;
          this.props.onGoogleResponse(requestType, parsedResponse, (documentId ? documentId : ''));
        });
      });
  }

  listFiles = (currentFolder: string) => {
    const driveFileQuery: string = `(mimeType contains 'application/vnd.google-apps.document' or mimeType contains 'application/vnd.google-apps.spreadsheet' or mimeType contains 'application/vnd.google-apps.presentation') and '${currentFolder}' in parents and 'me' in owners and trashed = false`;
    const scope = GoogleApiConstants.SCOPES.DRIVE_METADATA_READONLY;
    const listFiles = () => {
      return this.gapi.client.drive.files.list({
        'pageSize': 1000,
        'fields': `files(${FILE_FIELDS})`,
        q: driveFileQuery
      });
    }

    return this.verify(scope).then(() => this.execute(LIST_GOOGLE_DRIVE_FILES, listFiles));
  }

  listFolders = async (currentFolder: string) => {
    const driveFolderQuery: string = `mimeType contains 'application/vnd.google-apps.folder' and '${currentFolder}' in parents and 'me' in owners and trashed = false`;
    const scope = GoogleApiConstants.SCOPES.DRIVE_METADATA_READONLY;
    const listFolders = () => {
      return this.gapi.client.drive.files.list({
        'pageSize': 1000,
        'fields': `files(${FILE_FIELDS})`,
        q: driveFolderQuery
      });
    }

    return this.verify(scope).then(() => this.execute(LIST_GOOGLE_DRIVE_FOLDERS, listFolders));
  }

  getFileDetails = async (fileId: string) => {
    const scope = GoogleApiConstants.SCOPES.DRIVE_METADATA_READONLY;
    const getFileDetails = () => {
      return this.gapi.client.drive.files.get({
        fileId,
        fields: FILE_FIELDS
      });
    }

    return this.verify(scope).then(() => this.execute(GET_GOOGLE_FILE_DETAILS, getFileDetails));
  }
  
  shareFileWithAnyoneWithLink = (fileId: string) => {
    const scope = GoogleApiConstants.SCOPES.DRIVE;
    const role = GoogleApiConstants.PERMISSIONS.ROLES.WRITER;
    const type = GoogleApiConstants.PERMISSIONS.TYPES.ANYONE;
    const shareWithAnyoneWithLink = () => {
      return this.gapi.client.drive.permissions.create({
        'fileId': fileId,
        'resource': {
          'role': role,
          'type': type
        }
      });
    }
    return this.verify(scope).then(() => this.execute(SHARE_WITH_ANYONE_WITH_LINK, shareWithAnyoneWithLink, fileId));
  }

  revokeFileFromAnyoneWithLink = (fileId: string) => {
    const scope = GoogleApiConstants.SCOPES.DRIVE_FILE;
    const permissionId = GoogleApiConstants.PERMISSIONS.IDS.ANYONE_WITH_LINK;
    const revokeFromAnyoneWithLink = () => {
      return this.gapi.client.drive.permissions.delete({
        fileId,
        permissionId
      });
    }
    return this.verify(scope).then(() => this.execute(REVOKE_FROM_ANYONE_WITH_LINK, revokeFromAnyoneWithLink));
  }

  updateFilesAndFolders = async (folderId: string) => {
    this.listFolders(folderId);
    this.listFiles(folderId);
  }

  onFolderSelected = (folderId: string) => {
    this.updateFilesAndFolders(folderId);
  }

  onOpenDrive = async () => {
    this.updateFilesAndFolders('root');
  }

  onSignIn = () => {
    console.log('Signing in to Google');
    this.Auth.signIn(this.Auth.currentUser.get());
  }

  onSignOut = () => {
    console.log('Signing out of Google');
    this.Auth.signOut();
  }

  render() {
    let avatarURL: string = '';
    if (this.props.isSignedIn && this.gapi) {
      avatarURL = this.GoogleUserAvatar;
    }
    // TODO: set GoogleUserAvatar URL in the store, and broadcast to other users

    return (
      <div>
        { /* this.props.userId ? <img src={avatarURL} /> : null  */}
        { !this.props.documentId ? <GoogleAuth signIn={this.onSignIn} signOut={this.onSignOut} openDrive={this.onOpenDrive} /> : null }
        { !this.props.isDriveOpen ? <GoogleDoc documentId={this.props.documentId} endGoogleDoc={(this.props.ownerId === this.props.userId) ? this.endGoogleDoc : null} /> : <FileModal onFolderSelected={this.onFolderSelected} startGoogleDoc={this.startGoogleDoc} /> }
      </div>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    roomId: state.room.roomId,
    meetingType: state.room.meetingType,
    isDriveOpen: state.files.isDriveOpen,
    userId: state.auth.userId,
    isSignedIn: state.auth.isSignedIn,
    ownerId: state.activity.ownerId,
    documentId: state.activity.documentId,
    activityType: state.activity.activityType
   };
}

export default connect(mapStateToProps, { signedIn, signedOut, onGoogleResponse, startGoogleDoc, endGoogleDoc, selectFolder, documentChanged })(GoogleContainer);